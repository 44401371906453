import { render, staticRenderFns } from "./BuildingsList.vue?vue&type=template&id=4c3d8970&scoped=true&"
import script from "./BuildingsList.vue?vue&type=script&lang=js&"
export * from "./BuildingsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3d8970",
  null
  
)

export default component.exports